import React, { useEffect } from "react";
import { MASK2 } from "../../assets/img";

const ForeCast = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="sm:my-24 my-16">
      <section className="px-4 my-4 mx-auto h-full sm:flex block justify-center items-center text-white bg-black">
        <div className="p-8 md:w-1/2 w-full my-auto">
          <div className="flex flex-col text-start sm:font-normal xl:text-6xl lg:text-5xl md:text-4xl sm:text-3xl text-2xl">
            <span>D.O.P.E. GOLF</span>
            <span className="text-red-500 mt-1">FORECAST</span>
            <span className="text-red-500 mt-1">FEATURE</span>
          </div>

          <div className="py-4 lg:text-lg md:text-md sm:text-sm text-xs">
            Is your 7 Iron carry distance at 7AM the same as your 7 Iron carry
            distance at 4PM?
          </div>
          <div className="text-red-500 lg:text-xl md:text-lg sm:text-md text-sm">
            NO!
          </div>

          <div className="py-4 lg:text-lg md:text-md sm:text-sm text-xs">
            As the temperature increases or decreases throughout the day, this
            will impact how far your ball will carry. Additionally, the wind
            speed and direction throughout the day may increase or decrease.
            These atmospheric variables will affect the total carry distance for
            each club
          </div>
          <div className="lg:text-lg md:text-md sm:text-sm text-xs">
            Created for the competition golfer, the D.O.P.E. Golf{" "}
            <span className="text-red-500"> FORECAST FEATURE </span>
            will allow the golfer to set their Tee Time. The feature will then
            look ahead 6 hours and import the projected weather forecast
            throughout their round and compute how far each club will carry
            every hour during their round. Thus, giving the competition golfer
            more confidence knowing the exact carry distance for each club as
            atmospheric variables change throughout their round
          </div>
        </div>
        <div className="md:w-1/2 w-full md:min-h-[730px] sm:min-h-[530px] min-h-[400px] md:p-16 p-8 flex items-center">
          <img src={MASK2} alt/>
        </div>
      </section>
      <section className="px-4 my-4 mx-auto h-full sm:flex block justify-center items-center text-white bg-black">
        <div className="p-8 sm:w-1/2 w-full my-auto">
          <div className="flex flex-col text-start sm:font-normal xl:text-6xl lg:text-5xl md:text-4xl sm:text-3xl text-2xl">
            <span>D.O.P.E. GOLF</span>
            <span className="text-red-500 mt-1">FORECAST</span>
            <span className="text-red-500 mt-1">FEATURE</span>
          </div>
        </div>
        <div className="md:w-1/2 w-full lg:min-h-[700px] md:min-h-[500px] sm:min-h-[400px] min-h-[300px] md:p-8 p-4 flex items-center">
          <div className="container px-4 mx-auto max-w-6xl">
            <div className="w-full overflow-hidden sm:h-[650px] h-[250px]">
              <iframe
                className="object-contain"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/-RmUADCWI4A"
                title="All Sports Golf Battle | Dude Perfect"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
             
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForeCast;
