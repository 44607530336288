import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const AnnualIntroducer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="sm:my-24 my-16">
      <section className="px-4 my-4 mx-auto h-full sm:flex block justify-center items-center text-white bg-black">
        <div className="p-8 sm:w-1/2 w-full my-auto">
          <div className="flex flex-col text-start sm:font-normal xl:text-6xl lg:text-5xl md:text-4xl sm:text-3xl text-2xl">
            <span>D.O.P.E. GOLF</span>
            <span className="text-red-500 mt-1">INTRODUCER</span>
            <span className=" mt-1">COMPETITION</span>
          </div>
        </div>
        <div className="flex flex-col sm:w-1/2 w-full md:min-h-[730px] sm:min-h-[530px] min-h-[400px]">
          <div className="my-auto">
            <div className="flex flex-col text-center sm:font-normal xl:text-3xl lg:text-2xl md:text-xl sm:text-lg text-md">
              <span>
                TOP 4 <span className="text-red-500">Introducers</span> of the
                year 2023
              </span>
            </div>

            <div className="my-6 xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
              We value your support in growing the D.O.P.E. Golf family, and
              with each successful user introduction into our application, we
              will reward the annual TOP 4 Introducers of the year with an all
              expenses paid golf vacation with the D.O.P.E. Golf Team.
            </div>

            <div className="xl:text-3xl lg:text-2xl md:text-xl sm:text-lg text-md">
              <span>What you’ll win</span>
            </div>
            <div className="flex flex-col xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
              <span>
                - All expenses paid 3 days, 3 nights trip to Pinehurst Country
                Club
              </span>
              <span> - 1 Round on Pinehurst #2 </span>
              <span> - 1 Round on Pinehurst #4</span>
              <span> - 1 Round on The Cradle </span>
              <span> - FREE Lifetime use of the D.O.P.E. Golf APP </span>
              <span> - Bag of custom D.O.P.E. Golf Swag</span>
            </div>

            <div className="my-6 xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
              <Link to="/introducer/become-introducer" className="text-red-500">
                Click Here
              </Link>{" "}
              to Sign Up as a D.O.P.E. Golf Introducer and begin onboarding
              golfers to the D.O.P.E. Golf APP for your chance to WIN!
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AnnualIntroducer;
