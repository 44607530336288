import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {" "}
      <main className="text-white md:pt-[60px] sm:pt-[40px]">
        <section className="h-[50vh] bg-no-repeat w-full bg-[url('../../assets/img/icon/intro.webp')]">
          <div className="container mx-auto px-4 h-full flex justify-center items-center">
            <h1 className="lg:text-6xl sm:text-4xl text-3xl">Privacy Policy</h1>
          </div>
        </section>
        <section>
          <div className="container mx-auto px-4">
            <div className="lg:max-w-6xl mx-auto py-20 md:px-5">
              <p className="xl:text-xl text-base lg:text-lg md:text-lg first-letter:tracking-wider sm:leading-7 md:leading-7 lg:leading-8 xl:leading-8 font-thin">
                We do not sell or rent your personal information to third
                parties for their marketing purposes without your explicit
                consent and only use your information as described below. We
                view the protection of users' privacy as a very valuable
                community principle. We understand clearly that you and your
                information are one of our most important assets. We store and
                process your information on computers located in various
                geographies, including the United States. The servers are
                protected by physical as well as technological security devices
                offered by third-party vendors. Dopegolf.com does not guarantee
                the nature and extent of security offered by such vendors. This
                Privacy Policy describes the manner in which your data is
                collected and used by Dopegolf.com. You are advised to please
                read this Privacy Policy carefully. By accessing Dopegolf.com
                services, you consent to Dopegolf.com collecting and using your
                data in the ways described in this Privacy Policy.
              </p>

              <h4 className="lg:text-4xl sm:text-3xl text-2xl text-red-500 pt-16">
                What we collect
              </h4>
              <ul className="lg:text-lg sm:text-base list-disc px-9 font-thin tracking-wide opacity-60">
                <li className="pt-7">
                  We will automatically receive and collect certain anonymous
                  information in standard usage logs through our web server,
                  including computer-identification information obtained from
                  "cookies," sent to your browser from
                </li>
                <li className="pt-7">
                  Web server cookie stored on your hard drive
                </li>
                <li className="pt-7">
                  The domain server through which you access our service
                </li>
                <li className="pt-7">The type of computer you're using</li>
                <li className="pt-7">The type of web browser you're using</li>
                <li className="pt-7">Name including first and last name</li>
                <li className="pt-7">
                  Demographic profile (like your age, gender, occupation,
                  education, address, and durables owned)
                </li>
                <li className="pt-7">
                  Opinions of features on the sites (i.e., websites that are
                  managed, operated, or licensed by the sponsors of
                  dopegolf.com) websites managed, operated or licensed by
                  dopegolf.com
                </li>
                <li className="pt-7">
                  We may use the information to send you the promotional
                  messages of products occasionally.
                </li>
                <li className="pt-7">Number of times you access pages</li>
                <li className="pt-7">
                  You can terminate your account at any time. However, even if
                  your account is deleted or terminated, your information may
                  remain stored in archive on our servers.
                </li>
              </ul>

              <h4 className="lg:text-4xl sm:text-3xl text-2xl  text-red-500 pt-16">
                What we do with information we gather
              </h4>
              <ul className="lg:text-lg list-disc px-9 sm:text-base font-thin tracking-wide opacity-60">
                <li className="pt-7">
                  We will collect anonymous traffic information from you when
                  you visit our website.
                </li>
                <li className="pt-7">
                  We will collect personally identifiable information about you
                  only as part of a voluntary registration process, on-line
                  survey or contest, or any combination thereof.
                </li>
                <li className="pt-7">
                  Important: For legal reasons, we may be compelled to keep
                  certain data for a limited amount of time from the date of
                  your request for deletion of data.
                </li>
              </ul>
              <h4 className="lg:text-4xl sm:text-3xl text-2xl  text-red-500 pt-16">
                Link your other information
              </h4>
              <ul className="lg:text-lg list-disc px-9 sm:text-base font-thin tracking-wide opacity-60">
                <li className="pt-7">
                  Our website may contain links to other websites of interest.
                  However, once you leave our site via these links, you should
                  note that we have no control over the other website.
                  Therefore, we cannot guarantee the security or privacy of any
                  information you supply when visiting other sites; as such
                  sites are not governed by this privacy statement. You should
                  exercise caution and look at the privacy statement applicable
                  to the website in question.
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default PrivacyPolicy;
