import React, { useEffect } from "react";
import { DopeGolfBanner } from "../../assets/img";

const OverView = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="sm:my-24 my-16">
      <section className="my-4 mx-auto h-full sm:flex block justify-center items-center text-white bg-black">
        <div className="sm:w-1/2 w-full h-full my-auto">
          <img src={DopeGolfBanner} className="h-screen w-full object-cover" />
        </div>
        <div className="flex flex-col sm:w-1/2 w-full md:min-h-[730px] sm:min-h-[530px] min-h-[400px] px-4">
          <div className="flex justify-center items-center text-red-500 h-full lg:text-2xl md:text-xl sm:text-lg text-md">
            Who We Are
          </div>
          <div className="my-8 xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
            Founded by a U.S. Army Special Forces Green Beret Sniper, D.O.P.E.
            Golf is at the crossroads where golf and sniper technology meet.
          </div>
          <div className="xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
            Founded in 2018 by our CEO Ryan Tremblay, a Retired Green Beret
            Sniper and golf junkie, we created a Smart Phone application that
            merges the sniper ballistic technology Ryan used in combat with
            golf.
          </div>
          <div className="my-8 xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
            The parallels between the sniper world and golf are identical. Both
            revolve around ballistics and atmospheric variables. Golf and long
            range marksmanship rely on the use of doppler radars to collect the
            external ballistic characteristics of their bullet/golf ball. In the
            sniper world we collect the muzzle velocity (speed of the bullet)
            and how it performs in a given location. At D.O.P.E. Golf, we treat
            each club as its own “sniper rifle” since each club produces a
            unique launch angle and spin rate based on the loft and lie of the
            club. The total carry distance for that shot will vary based on
            atmospheric variables (temperature, humidity, barometric pressure,
            wind).
          </div>
          <div className="xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
            By utilizing a launch monitor, we can collect the average launch
            parameters (launch angle, spin rate, ball speed, spin axis) for each
            club that are unique to the golfer's swing and custom club set up. If
            the golfer can recreate that same swing that produces those “stock”
            launch parameters, the D.O.P.E. Golf APP will import the real time
            atmospherics based on the golfer's GPS location and will
            ballistically compute the total carry distance to a 99% accuracy
            given any changes in atmospheric variables.
          </div>
          <div className="my-8 xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
            YOU HAVE ONE OPPORTUNITY TO TAKE A SHOT.....DO YOU HAVE THE RIGHT
            DATA?
          </div>
        </div>
      </section>
    </div>
  );
};

export default OverView;
