import React, { useEffect } from "react";
import { TAB1 } from "../../assets/img";

const OverView = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="sm:my-24 my-16">
      <section className="px-4 my-4 mx-auto h-full sm:flex block justify-center items-center text-white bg-black">
        <div className="p-8 sm:w-1/2 w-full my-auto">
          <div className="flex flex-col text-start sm:font-normal xl:text-6xl lg:text-5xl md:text-4xl sm:text-3xl text-2xl">
            <span>D.O.P.E. GOLF</span>
            <span className="text-red-500 mt-1">INTRODUCER</span>
          </div>
          <div className="my-4 lg:text-lg md:text-md sm:text-sm text-xs">
            Are you a PGA Professional, Big Box Retailer, Mom and Pop Golf Shop,
            or an avid golfer who has a launch monitor?
          </div>
          <div className="lg:text-lg md:text-md sm:text-sm text-xs">
            <span className="text-red-500">SIGN UP </span>now to become a
            D.O.P.E. Golf INTRODUCER
          </div>
          <div className="my-4 lg:text-lg md:text-md sm:text-sm text-xs">
            By becoming a D.O.P.E. Golf INTRODUCER, you will gain access to the
            D.O.P.E. Golf INTRODUCER portal where you can sign up golfers to our
            Application and input their unique launch parameters collected from
            a launch monitor for each club into their user profile.
          </div>
          <div className="lg:text-xl md:text-lg sm:text-md text-sm">
            Additional service offered at ZERO set up cost to the Introducer:
          </div>
          <div className="flex flex-col lg:text-md md:text-sm sm:text-xs text-[12px]">
            <span>- Average cost of an hour lesson with a PGA pro: $100</span>
            <span>- Average cost of a entire bag club fitting: $350</span>
            <span className="text-red-500">
              - D.O.P.E. Golf Truing Combine: $100/hr{" "}
            </span>
            <div className="flex flex-col ml-10">
              <span>
                - 100% of the collection fee goes to the PGA Professional, Golf
                Store or individual
              </span>
              <span>
                - Truing Combine price will vary depending on INTRODUCER
              </span>
            </div>
          </div>

          <div className="my-4 lg:text-xl md:text-lg sm:text-md text-sm">
            D.O.P.E. Golf <span className="text-red-500"> TRUING COMBINE </span>
          </div>

          <div className="flex flex-col">
            <span>
              - Time: Roughly 45 min to an hour to collect and input the golfers
              launch parameters for their entire bag into their user profile
            </span>
            <span className="my-4">
              - Cost to collect: Roughly $100/hr (cost will vary based on
              INTRODUCER)
            </span>
          </div>
        </div>
        <div className="flex flex-col sm:w-1/2 w-full md:min-h-[730px] sm:min-h-[530px] min-h-[400px]">
          <div className="w-full md:p-16 p-8 flex items-center">
            <img src={TAB1} alt/>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OverView;
