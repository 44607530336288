import React, { useEffect } from "react";
import { KEN, MATT, ROSS, RYAN } from "../../assets/img";

const Team = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="h-screen w-screen">
      <div className="flex justify-center align-middle text-white ">
        <div className="grid gap-10 md:grid-cols-2 grid-cols-1 mt-8 px-2">
          <div className="flex">
            <img src={RYAN} className="md:h-44 h-32" />
            <div className="md:h-44 h-32 bg-gray-500 p-4 md:w-[430px] w-[300px]">
              <div className="text-red-500 xl:text-4xl lg:text-3xl md:text-2xl sm:text-xl text-lg">
                Ryan Tremblay
              </div>
              <div className="flex flex-col mt-4">
                <span className="xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
                  Founder, President & Chief Executive Officer
                </span>
                <span className="mt-2 xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
                  Green Beret, Sniper
                </span>
              </div>
            </div>
          </div>
          <div className="flex">
            <img src={KEN} className="md:h-44 h-32" />
            <div className="md:h-44 h-32 bg-gray-500 p-4 md:w-[430px] w-[300px]">
              <div className="text-red-500 xl:text-4xl lg:text-3xl md:text-2xl sm:text-xl text-lg">
                Ken Tannar
              </div>
              <div className="flex flex-col mt-4">
                <span className="xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
                  Co-Founder, Chief Technology
                </span>
                <span className="mt-2 xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
                  Officer, Physicist
                </span>
              </div>
            </div>
          </div>
          {/* <div className="flex">
            <img src={ROSS} className="md:h-44 h-32" />
            <div className="md:h-44 h-32 bg-gray-500 p-4 md:w-[430px] w-[300px]">
              <div className="text-red-500 xl:text-4xl lg:text-3xl md:text-2xl sm:text-xl text-lg">
                Ross Negele
              </div>
              <div className="flex flex-col mt-4">
                <span className="xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
                  Co-Founder, Chief Financial
                </span>
                <span className="mt-2 xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
                  Officer
                </span>
              </div>
            </div>
          </div> */}
          {/* <div className="flex">
            <img src={MATT} className="md:h-44 h-32" />
            <div className="md:h-44 h-32 bg-gray-500 p-4 md:w-[430px] w-[300px]">
              <div className="text-red-500 xl:text-4xl lg:text-3xl md:text-2xl sm:text-xl text-lg">
                Matt Barksdale
              </div>
              <div className="flex flex-col mt-4">
                <span className="xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
                  Co-Founder, Chief Operations Officer, Dir. Of Golf & Head
                  Professional at Pinehurst Resorts
                </span>
                <span className="mt-2 xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
                  & Head Professional at Pinehurst Resorts
                </span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Team;
